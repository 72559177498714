import { PollyClient } from '@aws-sdk/client-polly';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';

const REGION = "eu-central-1";
const IDENTITY_POOL_ID = "eu-central-1:dec9734e-c7fd-4549-af49-7825ca12ccf7";

export const pollyClient = new PollyClient({
    region: "eu-central-1",
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: REGION }),
      identityPoolId: IDENTITY_POOL_ID
    })
  })