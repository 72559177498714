import React, { useState, useEffect, useRef } from 'react';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { Authenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { LexRuntimeV2Client, RecognizeTextCommand } from '@aws-sdk/client-lex-runtime-v2';
import { speakWithPolly, stopAudio } from './speakWithPolly';
import { resetConversation, resetEndpoint, previousStep, nextStep } from './conversationManager'
import { addQuestionToConversation, addResponseToConversation } from './conversationManager'
import './chatbot.css'

Amplify.configure(awsExports);

// Configure Lex V2 client
const client = new LexRuntimeV2Client({
  region: "eu-central-1",
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region: "eu-central-1" }),
    identityPoolId: "eu-central-1:dec9734e-c7fd-4549-af49-7825ca12ccf7"
  })
});

function generateSessionId() {
  return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

const sessionId = generateSessionId();

function Chatbot() {
  const [userInput, setUserInput] = useState('');
  const [conversation, setConversation] = useState([]);
  const [pollyEnabled, setPollyEnabled] = useState(true);
  const [endpointEnabled, setEndpointEnabled] = useState(true);
  const messagesEndRef = useRef(null);
  let reset_flag = true;  

  const audio = new Audio();

  useEffect(() => {
    // console.log('pollyEnabled aggiornato:', pollyEnabled);
  }, [pollyEnabled])

  useEffect(() => {
    // console.log('endpointEnabled aggiornato:', endpointEnabled);
  }, [endpointEnabled])

  const handlePollyToggle = () => {
    // setPollyEnabled(!pollyEnabled);
    // console.log("Polly Enabled:", pollyEnabled);
    stopAudio();
    setPollyEnabled(prevPollyEnabled => {
      const newPollyEnabled = !prevPollyEnabled;
      console.log('PollyEnabled sarà:', newPollyEnabled);
      return newPollyEnabled;
    })
  };

  const handleEndpointToggle = () => {
    // setEndpointEnabled(!endpointEnabled);
    // console.log("Endpoint Enabled:", endpointEnabled);
    setEndpointEnabled(prevEndpointEnabled => {
      const newEndpointEnabled = !prevEndpointEnabled;
      console.log('EndpointEnabled sarà:', newEndpointEnabled);
      return newEndpointEnabled;
    })
  };

  function playSound(sound) {
    audio.src = sound;
    audio.play();
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavoir: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [conversation])

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const audioRef = useRef(null);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.load();
    }
  }, []);
 
  const handleVoiceInput = () => {

    const recognition = new window.webkitSpeechRecognition() || new window.SpeechRecognitionAlternative();
    recognition.lang = 'it-IT';

    recognition.onresult = async (event) => {
      const speechToText = event.results[0][0].transcript;
      console.log(`Hai detto: ${speechToText}`);
      sendMessageToLex(speechToText, false, 0);
    };
    recognition.start();
  };

  const handleVoiceInputArtificial = (step) => {

    const recognition = new window.webkitSpeechRecognition() || new window.SpeechRecognitionAlternative();
    recognition.lang = 'it-IT';

    recognition.onresult = async (event) => {
      const speechToText = event.results[0][0].transcript;
      console.log(`Hai detto: ${speechToText}`);
      
      sendMessageToLex(speechToText, true, step);
    };
    recognition.start();
  };

  function replaceDashes(inputString) {
    return inputString.replace(/(^|\n\n|\n) ?- /g, '$1 • ');
  }

  const sendMessageToLex = async (message, artificial, step) => {

    let lexResponse = "";

    updateUserConversation(message);

    // Use the recognizied text with Lex V2
    const params = {
      // botId: "MQVK2SSRTP",
      botId: "1HZQW8BQRT",
      botAliasId: "TSTALIASID",
      localeId: "it_IT",
      sessionId: sessionId,
      text: message
    };

    try {

      if (!artificial) {

        const command = new RecognizeTextCommand(params);
        // const { messages } = await client.send(command);     
        const { messages } = await client.send(command);     

        lexResponse = messages[0]?.content || "Nessuna risposta dal bot";

      } else if (artificial && step === 1) {        
        lexResponse = "Certamente, ti invio i 3 dossier e aggiorno i tuoi task e la tua agenda.";
      } else if (artificial && step === 2) {        
        lexResponse = "Certamente.";
      }
      
      updateBotConversation(lexResponse, artificial);      
      setUserInput(''); // pulisce input dopo l'invio
      
      if(pollyEnabled) {
        speakWithPolly(replaceDashes(lexResponse));
      }      

    } catch (err) {
      console.error('Errore nella comunicazione con il bot:', err);

      if(pollyEnabled) {
        speakWithPolly("Errore nella comunicazione con il bot");
      }    
    }
  };

  const updateUserConversation = (userMessage) => {
    setConversation(conversation => [...conversation,
      { from: 'Tu', message: userMessage }
    ]);
    
    addQuestionToConversation(userMessage, endpointEnabled);
  };

  const updateBotConversation = (botMessage, artificial) => {
    setConversation(conversation => [...conversation,
      { from: 'Alma', message: botMessage }
    ]);

    if (artificial) {
      setTimeout(() => {
        addResponseToConversation(botMessage, endpointEnabled);
      }, 3000);
    } else {
      addResponseToConversation(botMessage, endpointEnabled);
    }

    // addResponseToConversation(botMessage);

    if (artificial) {
      setTimeout(() => {
        resetConversation(true, endpointEnabled);
      }, 5000);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (reset_flag) {
      console.log("Session ID:", sessionId);

      sendMessageToLex(userInput, false, 0); 
      
    }
    reset_flag = true;
  };

  const handleReset = () => {
    resetEndpoint(true, null, endpointEnabled);
  }

  const handleSummary = async (event) => {
    event.preventDefault();

    const summaryInput = "Puoi fornirmi un sommario di quanto discusso sinora?"
    sendMessageToLex(summaryInput, false, 0); 
  }

  const handleNextStep = async (event) => {
    nextStep();
  }

  const handlePreviousStep = async (event) => {
    previousStep();
  }

  const handleEndFirst = async (event) => {
    handleVoiceInputArtificial(1);
  }

  const handleEndSecond = async (event) => {
    handleVoiceInputArtificial(2);    
  }

  return (
    <Authenticator>
      {({ signOut }) => (
        <div className='chat-container'>
          <header className='chat-header'>
            <div className="alma-virtual-assistant">
              <span className="first-letter">a<span className="symbol-above">&gt;</span></span>lma virtual assistant
            </div>
          </header>
          <div className="chat-messages">
            {conversation.map((entry, index) => (
              entry.from === "Alma" ?
                <p key={index} className='bot-message' style={{ whiteSpace: 'pre-wrap'}}><strong>{entry.from}: </strong>{entry.message}</p>
                :
                <p key={index} className='user-message'><strong>{entry.from}: </strong>{entry.message}</p>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <form onSubmit={handleSubmit} className='chat-form'>
            <input
              type="text"
              value={userInput}
              onChange={handleInputChange}
              placeholder="Scrivi un messaggio..."
              className='chat-input'
            />
          </form>  
          <div className='section-title'>
            <hr /><span>Gestione Bot</span><hr />
          </div>
          <div className='button-group'>                        
            <button onClick={() => {handleVoiceInput(); playSound('/mic_ready.mp3');}} className='button voice-button'>Parla</button>
            {/* <button onClick={(stopAudio)} className='button voice-button'>Silenzia Polly</button>                       */}
            <button onClick={resetEndpoint} className='button reset-endpoint-button' disabled={!endpointEnabled}>Reset Endpoint</button> 
          </div>
          {/* <div className='section-title'>
            <hr /><span>Gestione Scenario</span><hr />
          </div>
          <div className='button-group'>
            <button onClick={() => {handleEndFirst(); playSound('/mic_ready.mp3');}} className='button scenario-button' disabled={!endpointEnabled}>Fine Scenario 1</button> 
            <button onClick={() => {handleEndSecond(); playSound('/mic_ready.mp3');}} className='button scenario-button' disabled={!endpointEnabled}>Fine Scenario 2</button> 
            <button onClick={handleSummary} className='button summary-button' disabled={!endpointEnabled}>Fine Scenario 3 (Sommario)</button>
          </div> */}
          <div className='section-title'>
            <hr /><span>Navigazione</span><hr />
          </div>
          <div className='button-group'> 
            <button onClick={handlePreviousStep} className='button modification-button' disabled={!endpointEnabled}>Previous Step</button> 
            <button onClick={handleNextStep} className='button modification-button' disabled={!endpointEnabled}>Next Step</button> 
          </div>
          <div className='button-group'> 
            <button onClick={handlePollyToggle} className={`button toggle-polly-button ${pollyEnabled ? 'enabled' : 'disabled'}`}>
              {pollyEnabled ? 'Polly Attivato' : 'Polly Disattivato' }
            </button>
            <button onClick={handleEndpointToggle} className={`button toggle-endpoint-button ${endpointEnabled ? 'enabled' : 'disabled'}`}>
              {endpointEnabled ? 'Endpoint Attivato' : 'Endpoint Disattivato' }
            </button>             
          </div>             
        </div>
      )}
    </Authenticator>
  );
}

export default withAuthenticator(Chatbot, { hideSignUp: true });

